import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { about, allInOne, barkodOxuyucu, barkodPrinter, barkodProcessor, cardReader, dataTerminal, hero, pulQutulari, sertifikat1, sertifikat2, sertifikat3, sertifikat4, sertifikat5, sertifikat6, sertifikat7 } from '../../assets/index';
import { useTranslation } from 'react-i18next';
import Header from '../../Components/Header/Header';



const About = () => {

  const { t, i18n } = useTranslation();

  useEffect(() => {
    AOS.init({ duration: 1500 });
  }, []);
  return (
 <>
 <Header/>
   <div className='bg-[#FAFAFA] pb-[144px]'>
      <div>
     <div className="pt-[40px] pb-[80px] max-w-container mx-auto">
       <div className='h-[719px] flex justify-between'>
        <div>
          <img className='w-[345px]' src={about}></img>
        </div>
        <div className='w-[742px]'>
          <div>
            <p className='text-[#405d72] text-base font-semibold mb-4'>Haqqımızda</p>
            <p className='text-black text-3xl font-bold mb-3'>İnşaat sektorunda qabaqcıl şirkət olduğumuza əminik!</p>
            <p className='text-black text-sm font-normal mb-4'>“Fuga MMC” Məhdud Məsuliyyətli Cəmiyyəti № İN/2-2085/2016 02 Noyabr 2016-cı il tarixli lisenziya əsasında uzun müddət fəaliyyət göstərən, hal-hazırda iri və dinamik inkişaf edən müəssisədir. Şirkət göstərilən xidmətlərin keyfiyyətini öz fəaliyyətinin əsas prinsipi hesab edir ki, bu da müəyyən sahələrdə liderliyə iddia etməyə zəmin yaradır. Başladığımız hər bir işi zamanında tamamladıq və verdiyimiz sözləri daima yerinə yetirdik. Çalışdığımız insanlara hər zaman güvəndik və onların etibarını qazandıq.</p>
            <p className='text-black text-base font-semibold mb-3'>Bizim missiyamız</p>
            <p className='text-black text-sm font-normal mb-4'>“Fuga MMC” yarandığı gündən hesab edir ki, iqtisadiyyatın inkişafı üçün atılan addımlar iqtisadiyyatın inkişafı üçün yetərli deyil. Biz inanırıq ki, iqtisadi layihələr dünyanın mədəniyyət kolleksiyası və müxtəlif sosial davranışlarla əlaqələndirildikdə və onlardan ilhamlandıqda səmərəli şəkildə həyata keçirilə bilər. “Fuga MMC” bu dəyərli irsi yaratmaq, qorumaq və gənclərə ötürmək missiyasını dünən olduğu kimi eyni həvəslə yerinə yetirir. “Fuga MMC” Azərbaycanın mədəni dəyərlərinə təkcə ölkəmiz üçün deyil, bütün dünya üçün böyük sərvət kimi baxır və bu dəyərlərin qorunub saxlanmasını və gələcək nəsillərə çatdırılmasını öz missiyasının bir hissəsi hesab edir.</p>
            <p className='text-black text-base font-semibold mb-3'>Əsas prinsiplərimiz</p>
            <p className='text-black text-sm font-normal mb-4'>Şirkət keyfiyyəti fəaliyyətinin əsas prinsipi hesab edir ki, bu da müəyyən sahələrdə liderlik iddiasında mühüm rol oynayır.</p>
            <p className='text-black text-base font-semibold mb-3'>Məqsədlərimiz</p>
            <p className='text-black text-sm font-normal mb-4'>Şirkət əsasən tikinti ilə məşğuldur. Hazırda biz tikinti və istehsalat sahələrində uğurla fəaliyyət göstəririk. Ölkəmizdə sürətlə inkişaf edən tikinti sektorunda zəngin təcrübəmizlə qarşıya qoyduğumuz məqsədlərə geniş və dinamik heyətlə nail oluruq. Başladığımız hər işi vaxtında başa vurduq və həmişə verdiyimiz sözlərə əməl etdik. Biz çalışdığımız insanlara həmişə etibar etmişik və onların etimadını qazanmışıq. Bu etimaddan aldığımız güc və ilhamla tikinti-istehsalat sahəsində gördüyümüz işlərlə xalq təsərrüfatına öz töhfəmizi verməklə bütün işləri vaxtında, qüsursuz və standartlara uyğun uğurla başa vurduq. Biz görülən işlərin keyfiyyətini nəzərə alaraq, əməkdaşlarımızın və ətraf mühitin təhlükəsizliyini hər zaman hər şeydən üstün tutmuşuq. Qarşıdakı illərdə məqsədimiz layihələrimizə müasir texnologiyada innovasiyaları tətbiq etməklə, sürətlə inkişaf edən tikinti sektorunda mövqeyimizi daha da möhkəmləndirməkdir. Yüksək təcrübəli kadrlarımız və texniki imkanlarımızla daha da böyük layihələr həyata keçirəcəyimizə inanırıq.</p>
          </div>
        </div>
       </div>
      </div>
      </div>
      <div className='bg-[#F4F4F4]'>
      <div className="pt-[80px] pb-[80px] max-w-container mx-auto">
       <div className='h-[839px]'>
     <p className='text-center text-black text-3xl font-bold mx-auto'>Sertifikatlarımız</p>
     <div className='mt-[40px] grid grid-cols-4 gap-5 w-full'>
<div className='w-[270px]'><img src={sertifikat1}></img>  </div>
<div className='w-[270px]'><img src={sertifikat2}></img>  </div>
<div className='w-[270px]'><img src={sertifikat3}></img>  </div>
<div className='w-[270px]'><img src={sertifikat4}></img>  </div>
<div className='w-[270px]'><img src={sertifikat5}></img>  </div>
<div className='w-[270px]'><img src={sertifikat6}></img>  </div>
<div className='w-[270px]'><img src={sertifikat7}></img>  </div>
     </div>

       </div>
      </div>
      </div>
   </div></>
  );
};

export default About;
