import React from "react";
import Heading from "../../Components/home/Heading";
import AboutUs from '../../Components/home/About/About';
import Team from "../../Components/home/Team/Team";
import { useTranslation } from "react-i18next";
import { hero } from "../../assets";
import Header from "../../Components/Header/Header";

export default function Services() {

  const { t, i18n } = useTranslation();
  return (
    <>
    <Header/>
    <div className="mx-auto bg-[#FAFAFA] pt-[40px] pb-[144px]">
       <div className="max-w-container mx-auto h-[713px]">
    <p className="text-[#405d72] text-base font-semibold mb-4">Xidmətlərimiz</p>
    <p className="text-black text-3xl font-bold mb-3">Professional inşaat xidmətlərimiz</p>
    <p className="text-black text-base font-semibold mb-6">Peşəkar yanaşma, innovativ texnologiyalar və mükəmməlliyə doğru hər addımda xidmətinizdəyik.</p>
    <p className="text-black text-base font-normal mb-10">Bizim əsas məqsədimiz, tikinti sahəsində mükəmməlliyi təmin edərək, müasir texnologiyaların və innovativ həllərin tətbiqi ilə hər bir layihənin keyfiyyətini artırmaqdır. Peşəkar komandamız, inşaat prosesinin hər mərhələsində – layihələndirmədən başlayaraq, tikintinin idarə olunmasına və son tamamlama işlərinə qədər – yüksək standartlara uyğun xidmət göstərir.</p>
    <div className='grid grid-cols-3 h-[225px] gap-5'>
        <div className='rounded-lg border border-[#405d72]/20 w-[360px] hover:bg-[#405d72] hover:text-[white] group transition duration-300 ease-in-out'>
  <div className='mt-4 mb-4 mr-6 ml-6 h-[193px]'>
    <p className='mb-[53px] text-[#5f5f5f] text-lg font-normal group-hover:text-white'>01</p>
    <p className='mb-[8px] text-[#5f5f5f] text-lg font-normal group-hover:text-white'>SVG</p>
    <p className='mb-[20px] text-black text-xl font-bold group-hover:text-white'>Əsas xidmətlər</p>
    <a href="/services/all"><p className='text-black text-sm font-normal group-hover:text-white'>Daha ətraflı</p></a>
  </div>
</div>
<div className='rounded-lg border border-[#405d72]/20 w-[360px] hover:bg-[#405d72] hover:text-[white] group transition duration-300 ease-in-out'>
  <div className='mt-4 mb-4 mr-6 ml-6 h-[193px]'>
    <p className='mb-[53px] text-[#5f5f5f] text-lg font-normal group-hover:text-white'>02</p>
    <p className='mb-[8px] text-[#5f5f5f] text-lg font-normal group-hover:text-white'>SVG</p>
    <p className='mb-[20px] text-black text-xl font-bold group-hover:text-white'>İnfrastruktur</p>
    <a href="/services/infrastruktur"><p className='text-black text-sm font-normal group-hover:text-white'>Daha ətraflı</p></a>
  </div>
</div>
<div className='rounded-lg border border-[#405d72]/20 w-[360px] hover:bg-[#405d72] hover:text-[white] group transition duration-300 ease-in-out'>
  <div className='mt-4 mb-4 mr-6 ml-6 h-[193px]'>
    <p className='mb-[53px] text-[#5f5f5f] text-lg font-normal group-hover:text-white'>03</p>
    <p className='mb-[8px] text-[#5f5f5f] text-lg font-normal group-hover:text-white'>SVG</p>
    <p className='mb-[20px] text-black text-xl font-bold group-hover:text-white'>Tikinti növləri</p>
    <a href="/services/tikinti"><p className='text-black text-sm font-normal group-hover:text-white'>Daha ətraflı</p></a>
  </div>
</div>
<div className='rounded-lg border border-[#405d72]/20 w-[360px] hover:bg-[#405d72] hover:text-[white] group transition duration-300 ease-in-out'>
  <div className='mt-4 mb-4 mr-6 ml-6 h-[193px]'>
    <p className='mb-[53px] text-[#5f5f5f] text-lg font-normal group-hover:text-white'>04</p>
    <p className='mb-[8px] text-[#5f5f5f] text-lg font-normal group-hover:text-white'>SVG</p>
    <p className='mb-[20px] text-black text-xl font-bold group-hover:text-white'>İnteryer</p>
    <a href="/services/interyer"><p className='text-black text-sm font-normal group-hover:text-white'>Daha ətraflı</p></a>
  </div>
</div>
<div className='rounded-lg border border-[#405d72]/20 w-[360px] hover:bg-[#405d72] hover:text-[white] group transition duration-300 ease-in-out'>
  <div className='mt-4 mb-4 mr-6 ml-6 h-[193px]'>
    <p className='mb-[53px] text-[#5f5f5f] text-lg font-normal group-hover:text-white'>05</p>
    <p className='mb-[8px] text-[#5f5f5f] text-lg font-normal group-hover:text-white'>SVG</p>
    <p className='mb-[20px] text-black text-xl font-bold group-hover:text-white'>Eksteryer</p>
    <a href="/services/eksteryer"><p className='text-black text-sm font-normal group-hover:text-white'>Daha ətraflı</p></a>
  </div>
</div>
<div className='rounded-lg border border-[#405d72]/20 w-[360px] hover:bg-[#405d72] hover:text-[white] group transition duration-300 ease-in-out'>
  <div className='mt-4 mb-4 mr-6 ml-6 h-[193px]'>
    <p className='mb-[53px] text-[#5f5f5f] text-lg font-normal group-hover:text-white'>06</p>
    <p className='mb-[8px] text-[#5f5f5f] text-lg font-normal group-hover:text-white'>SVG</p>
    <p className='mb-[20px] text-black text-xl font-bold group-hover:text-white'>Texnika</p>
    <a href="/services/texnika"><p className='text-black text-sm font-normal group-hover:text-white'>Daha ətraflı</p></a>
  </div>
</div>
        </div>
     </div>
     
</div>
    </>
  );
}
