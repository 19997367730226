import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { about, allInOne, barkodOxuyucu, barkodPrinter, barkodProcessor, cardReader, dataTerminal, hero, pulQutulari } from '../../../assets/index';
import { useTranslation } from 'react-i18next';


export const products = [
  {
    image: pulQutulari,
    name: "Bio-L birtərəfli", 
    path: "/products/1",
    content: "MƏRKƏZİ SIRA ÜZƏRİNDƏ VƏ EYNİ ANDA BİTKİ ARASI SİRZƏYİ ALAQ OTLArdan təmizləmək..."
  },
  {
    image: allInOne,
    name: "Bio-L birtərəfli",
    path: "/products/2",
    content: "MƏRKƏZİ SIRA ÜZƏRİNDƏ VƏ EYNİ ANDA BİTKİ ARASI SİRZƏYİ ALAQ OTLArdan təmizləmək..."
  },
  {
    image: barkodProcessor,
    name: "Bio-L birtərəfli",
    path: "/products/3",
    content: "MƏRKƏZİ SIRA ÜZƏRİNDƏ VƏ EYNİ ANDA BİTKİ ARASI SİRZƏYİ ALAQ OTLArdan təmizləmək..."
  },

  {
    image: cardReader,
    name: "Bio-L birtərəfli",
    path: "/products/4",
    content: "MƏRKƏZİ SIRA ÜZƏRİNDƏ VƏ EYNİ ANDA BİTKİ ARASI SİRZƏYİ ALAQ OTLArdan təmizləmək..."
  }

];

const About = () => {

  const { t, i18n } = useTranslation();

  useEffect(() => {
    AOS.init({ duration: 1500 });
  }, []);
  return (
   <div>
     <div className="pt-[80px] pb-[80px] max-w-container mx-auto ">
       <div className='h-[456px] flex justify-between'>
        <div>
          <img className='w-[345px]' src={about}></img>
        </div>
        <div className='w-[742px]'>
          <div className='mt-[88px] mb-[88px]'>
            <p className='text-[#405d72] text-base font-semibold mb-4'>Haqqımızda</p>
            <p className='text-black text-3xl font-bold mb-3'>İnşaat sektorunda qabaqcıl şirkət olduğumuza əminik!</p>
            <p className='mb-3'>“Fuga MMC” Məhdud Məsuliyyətli Cəmiyyəti № İN/2-2085/2016 02 Noyabr 2016-cı il tarixli lisenziya əsasında uzun müddət fəaliyyət göstərən, hal-hazırda iri və dinamik inkişaf edən müəssisədir. Şirkət göstərilən xidmətlərin keyfiyyətini öz fəaliyyətinin əsas prinsipi hesab edir ki, bu da müəyyən sahələrdə liderliyə iddia etməyə zəmin yaradır. Başladığımız hər bir işi zamanında tamamladıq və verdiyimiz sözləri daima yerinə yetirdik. Çalışdığımız insanlara hər zaman güvəndik və onların etibarını qazandıq.</p>
            <a href='/about'><button className='w-[146px] h-10 bg-[#5f5f5f] rounded-lg text-white text-base font-normal'>Daha ətraflı</button></a>
          </div>
        </div>
       </div>
      </div>
   </div>
  );
};

export default About;
