import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { allInOne, barkodOxuyucu, barkodPrinter, barkodProcessor, cardReader, contact, dataTerminal, hero, pulQutulari } from '../../../assets/index';
import { useTranslation } from 'react-i18next';


const Contact = () => {

  const { t, i18n } = useTranslation();

  useEffect(() => {
    AOS.init({ duration: 1500 });
  }, []);
  return (
   <div>
     <div className="pt-[80px] pb-[140px] max-w-container mx-auto">
       <div className='h-[478px] flex justify-between'>
        <img src={contact}></img>
       </div>
      </div>
   </div>
  );
};

export default Contact;
