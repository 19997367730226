import React, { useState, useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { hero, projects1, projects2, projects3, projects4 } from '../../assets/index';
import { useTranslation } from 'react-i18next';
import Header from "../../Components/Header/Header";

const Projects = () => {
  const { t, i18n } = useTranslation();
  
  // Projeler listesi
  const finishedProjects = [
    { id: 1, name: 'Layihə1', image: projects1 },
    { id: 2, name: 'Layihə2', image: projects2 },
    { id: 3, name: 'Layihə3', image: projects3 },
    { id: 4, name: 'Layihə4', image: projects4 },
    { id: 9, name: 'Layihə9', image: projects1 },
    { id: 10, name: 'Layihə10', image: projects2 },
    { id: 11, name: 'Layihə11', image: projects3 },
    { id: 12, name: 'Layihə12', image: projects4 }
  ];

  const ongoingProjects = [
    { id: 5, name: 'Layihə5', image: projects1 },
    { id: 6, name: 'Layihə6', image: projects2 },
    { id: 7, name: 'Layihə7', image: projects3 },
    { id: 8, name: 'Layihə8', image: projects4 },
    { id: 9, name: 'Layihə5', image: projects1 },
    { id: 10, name: 'Layihə6', image: projects2 },
    { id: 11, name: 'Layihə7', image: projects3 },
    { id: 12, name: 'Layihə8', image: projects4 }
  ];

  // Proje listesi için state
  const [projects, setProjects] = useState(finishedProjects); // Default olarak bitmiş projeler
  const [isOngoing, setIsOngoing] = useState(false); // Hangi proje türü gösteriliyor kontrolü için

  useEffect(() => {
    AOS.init({ duration: 1500 });
  }, []);

  // Başlık ve linkler dinamik olarak değişiyor
  const heading = isOngoing ? 'Davam edən Layihələr' : 'Tamamladığımız Layihələr';
  const viewAllLink = isOngoing ? '/davam-eden-layiheler' : '/bitmis-layiheler';

  return (
    <>
    <Header/>
    <div className='bg-[#f4f4f4]'>
      <div className="pt-[80px] pb-[80px] max-w-container mx-auto">
        <div className='h-[115px] mb-[40px] flex justify-between'>
          <div className='w-[463px]'>
            <p className='text-[#405d72] text-base font-semibold mb-4'>Layihələrimiz</p>
            <p className='text-black text-3xl font-bold mb-3'>{heading}</p>
            <div className='flex'>
              {/* Tıklamayla proje listesini ve başlığı değiştirin */}
              <p
                className={`underline text-[#405d72] text-base font-normal mr-[28px] cursor-pointer ${!isOngoing ? 'font-bold' : ''}`}
                onClick={() => { setProjects(finishedProjects); setIsOngoing(false); }}
              >
                Bitmiş layihələr
              </p>
              <p
                className={`underline text-[#405d72] text-base font-normal cursor-pointer ${isOngoing ? 'font-bold' : ''}`}
                onClick={() => { setProjects(ongoingProjects); setIsOngoing(true); }}
              >
                Davam edən layihələr
              </p>
            </div>
          </div>
        </div>

        {/* Projeler grid sistemi ile gösteriliyor */}
        <div className='h-343px] grid grid-cols-4 gap-5'>
          {projects.map((project) => (
            <div key={project.id} className='bg-[#F4F4F4]'>
              <img src={project.image} alt={project.name} className='h-[209px] rounded-lg mb-3' />
              <p className='text-black text-base font-semibold'>{project.name}</p>
            </div>
          ))}
        </div>
      </div>
    </div></>
  );
};

export default Projects;
