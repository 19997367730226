import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { allInOne, barkodOxuyucu, barkodPrinter, barkodProcessor, cardReader, dataTerminal, pulQutulari } from '../../../assets/index';
import { useTranslation } from 'react-i18next';


export const products = [
  {
    image: pulQutulari,
    name: "Bio-L birtərəfli", 
    path: "/products/1",
    content: "MƏRKƏZİ SIRA ÜZƏRİNDƏ VƏ EYNİ ANDA BİTKİ ARASI SİRZƏYİ ALAQ OTLArdan təmizləmək..."
  },
  {
    image: allInOne,
    name: "Bio-L birtərəfli",
    path: "/products/2",
    content: "MƏRKƏZİ SIRA ÜZƏRİNDƏ VƏ EYNİ ANDA BİTKİ ARASI SİRZƏYİ ALAQ OTLArdan təmizləmək..."
  },
  {
    image: barkodProcessor,
    name: "Bio-L birtərəfli",
    path: "/products/3",
    content: "MƏRKƏZİ SIRA ÜZƏRİNDƏ VƏ EYNİ ANDA BİTKİ ARASI SİRZƏYİ ALAQ OTLArdan təmizləmək..."
  },

  {
    image: cardReader,
    name: "Bio-L birtərəfli",
    path: "/products/4",
    content: "MƏRKƏZİ SIRA ÜZƏRİNDƏ VƏ EYNİ ANDA BİTKİ ARASI SİRZƏYİ ALAQ OTLArdan təmizləmək..."
  }

];

const ServicesShort = () => {

  const { t, i18n } = useTranslation();

  useEffect(() => {
    AOS.init({ duration: 1500 });
  }, []);
  return (
   <div className='bg-[#f4f4f4]'>
     <div className="pt-[80px] pb-[80px] max-w-container mx-auto ">
        <div className='mb-[20px] flex justify-between h-[225px]'>
     <div className='w-[610px]'>
   <p className='text-[#405d72] text-base font-semibold mb-4'>Xidmətlərimiz</p>
   <p className='text-black text-3xl font-bold mb-3'>Professional inşaat xidmətlərimiz</p>
   <p className='text-black text-base font-normal mb-3'>Peşəkar yanaşma, innovativ texnologiyalar və mükəmməlliyə doğru hər addımda xidmətinizdəyik.</p>
     <a href='/services'><button className='w-[146px] h-10 bg-[#5f5f5f] rounded-lg text-white text-base font-normal'>Daha ətraflı</button></a>
     </div>
     <div className='w-[485px] flex justify-between'>
     <div className='rounded-lg border border-[#405d72]/20 w-[230px] hover:bg-[#405d72] hover:text-[white] group transition duration-300 ease-in-out'>
  <div className='mt-4 mb-4 mr-6 ml-6 h-[193px]'>
    <p className='mb-[53px] text-[#5f5f5f] text-lg font-normal group-hover:text-white'>01</p>
    <p className='mb-[8px] text-[#5f5f5f] text-lg font-normal group-hover:text-white'>SVG</p>
    <p className='mb-[20px] text-black text-xl font-bold group-hover:text-white'>Əsas xidmətlər</p>
   <a href='/services/all'> <p className='text-black text-sm font-normal group-hover:text-white'>Daha ətraflı</p></a>
  </div>
</div>

<div className='rounded-lg border border-[#405d72]/20 w-[230px] hover:bg-[#405d72] hover:text-[white] group transition duration-300 ease-in-out'>
  <div className='mt-4 mb-4 mr-6 ml-6 h-[193px]'>
    <p className='mb-[53px] text-[#5f5f5f] text-lg font-normal group-hover:text-white'>02</p>
    <p className='mb-[8px] text-[#5f5f5f] text-lg font-normal group-hover:text-white'>SVG</p>
    <p className='mb-[20px] text-black text-xl font-bold group-hover:text-white'>İnfrastruktur</p>
   <a href='/services/infrastruktur'> <p className='text-black text-sm font-normal group-hover:text-white'>Daha ətraflı</p></a>
  </div>
</div>
     </div>
        </div>
        <div className='grid grid-cols-4 h-[225px] gap-5'>
        <div className='rounded-lg border border-[#405d72]/20 w-[270px] hover:bg-[#405d72] hover:text-[white] group transition duration-300 ease-in-out'>
  <div className='mt-4 mb-4 mr-6 ml-6 h-[193px]'>
    <p className='mb-[53px] text-[#5f5f5f] text-lg font-normal group-hover:text-white'>03</p>
    <p className='mb-[8px] text-[#5f5f5f] text-lg font-normal group-hover:text-white'>SVG</p>
    <p className='mb-[20px] text-black text-xl font-bold group-hover:text-white'>Tikinti növləri</p>
   <a href='/services/tikinti'> <p className='text-black text-sm font-normal group-hover:text-white'>Daha ətraflı</p></a>
  </div>
</div>
<div className='rounded-lg border border-[#405d72]/20 w-[270px] hover:bg-[#405d72] hover:text-[white] group transition duration-300 ease-in-out'>
  <div className='mt-4 mb-4 mr-6 ml-6 h-[193px]'>
    <p className='mb-[53px] text-[#5f5f5f] text-lg font-normal group-hover:text-white'>04</p>
    <p className='mb-[8px] text-[#5f5f5f] text-lg font-normal group-hover:text-white'>SVG</p>
    <p className='mb-[20px] text-black text-xl font-bold group-hover:text-white'>İnteryer</p>
   <a href='/services/interyer'> <p className='text-black text-sm font-normal group-hover:text-white'>Daha ətraflı</p></a>
  </div>
</div>
<div className='rounded-lg border border-[#405d72]/20 w-[270px] hover:bg-[#405d72] hover:text-[white] group transition duration-300 ease-in-out'>
  <div className='mt-4 mb-4 mr-6 ml-6 h-[193px]'>
    <p className='mb-[53px] text-[#5f5f5f] text-lg font-normal group-hover:text-white'>05</p>
    <p className='mb-[8px] text-[#5f5f5f] text-lg font-normal group-hover:text-white'>SVG</p>
    <p className='mb-[20px] text-black text-xl font-bold group-hover:text-white'>Eksteryer</p>
    <a href='services/eksteryer'><p className='text-black text-sm font-normal group-hover:text-white'>Daha ətraflı</p></a>
  </div>
</div>
<div className='rounded-lg border border-[#405d72]/20 w-[270px] hover:bg-[#405d72] hover:text-[white] group transition duration-300 ease-in-out'>
  <div className='mt-4 mb-4 mr-6 ml-6 h-[193px]'>
    <p className='mb-[53px] text-[#5f5f5f] text-lg font-normal group-hover:text-white'>06</p>
    <p className='mb-[8px] text-[#5f5f5f] text-lg font-normal group-hover:text-white'>SVG</p>
    <p className='mb-[20px] text-black text-xl font-bold group-hover:text-white'>Texnika</p>
    <a href='services/texnika'><p className='text-black text-sm font-normal group-hover:text-white'>Daha ətraflı</p></a>
  </div>
</div>
        </div>
      </div>
   </div>
  );
};

export default ServicesShort;
