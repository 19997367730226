import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import { t } from "i18next";
import { carousel1, carousel2, carousel3, logo } from "../../../assets";
import Header from "../../Header/Header";
import MainHeader from "../../MainHeader/MainHeader";

const Carousel = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [showText, setShowText] = useState(false);
  const [showButton, setShowButton] = useState(false);

  const slides = [
    { img: carousel1, text: "Xəyallarınızı Gerçəyə Çevirən Etibarlı İnşaat Tərəfdaşınız", content: "Fuga İnşaat, tikintinin hər mərhələsində müasir standartlara cavab verən peşəkar həllər təklif edir." },
    { img: carousel2, text: "Xəyallarınızı Gerçəyə Çevirən Etibarlı İnşaat Tərəfdaşınız", content: "Fuga İnşaat, tikintinin hər mərhələsində müasir standartlara cavab verən peşəkar həllər təklif edir."  },
    { img: carousel3, text: "Xəyallarınızı Gerçəyə Çevirən Etibarlı İnşaat Tərəfdaşınız", content: "Fuga İnşaat, tikintinin hər mərhələsində müasir standartlara cavab verən peşəkar həllər təklif edir." },
  ];

  useEffect(() => {
    const textTimer = setTimeout(() => {
      setShowText(true);
    }, 500);

    const buttonTimer = setTimeout(() => {
      setShowButton(true);
    }, 1500);

    return () => {
      clearTimeout(textTimer);
      clearTimeout(buttonTimer);
    };
  }, [activeIndex]);

  return (
    <div className="relative">
      {/* Header is placed outside the Swiper for it to stay on top */}
      <MainHeader/>

      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        onSlideChange={(swiper) => {
          setShowText(false);
          setShowButton(false);
          setActiveIndex(swiper.activeIndex);
        }}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper"
      >
        {slides.map((slide, index) => (
          <SwiperSlide key={index}>
            <div className="relative">
              <img src={slide.img} alt={`Slide ${index + 1}`} className="w-full h-[720px] object-cover" />
              {activeIndex === index && (
                <div className="absolute inset-0 flex flex-col justify-center items-center lg:items-start text-white p-4 lg:p-8 transition-opacity duration-1000 bg-black/50 lg:bg-blue-900/50">
                  <div className="w-[90%] lg:w-[80%] mx-auto mb-6 lg:mb-14 text-center lg:text-left">
                    <h2 className={`text-white text-5xl font-bold mb-4 mt-[86px] lg:mb-5 transition-all w-[60%] duration-1000 transform ${showText ? 'translate-y-0 opacity-100' : '-translate-y-10 opacity-0'}`}>
                      {t(slide.text)}
                    </h2>
                    <h2 className={` text-white text-xl font-normal mb-4 lg:mb-7 transition-all w-[50%] duration-1000 transform ${showText ? 'translate-y-0 opacity-100' : '-translate-y-10 opacity-0'}`}>
                      {t(slide.content)}
                    </h2>

                    <div className="">
                      <a href="/about">
                        <button className={`w-[146px] h-10 bg-[#5f5f5f] rounded-lg transition-all duration-1000 transform ${showButton ? 'translate-y-0 opacity-100' : '-translate-y-10 opacity-0'}`}>
                          {t("Daha ətraflı")}
                        </button>
                      </a>
                    </div>
                    <h2 className={`text-white text-base font-normal mt-[136px] mb-4 lg:mb-10 transition-all w-[50%] duration-1000 transform ${showText ? 'translate-y-0 opacity-100' : '-translate-y-10 opacity-0'}`}>
                      Bizimlə əlaqə saxlayın
                    </h2>
                    <p className=" text-white text-base font-normal">+994 00 000 00 00</p>
                  </div>
                </div>
              )}
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default Carousel;
