import React, { useState, useRef, useEffect } from "react";
import { FaChevronDown, FaChevronUp, FaTimes } from 'react-icons/fa';
import { logo, logoOther, mpost } from "../../assets/index";
import { useTranslation, initReactI18next } from "react-i18next";
import { azflag, engflag, rusflag } from '../../assets/index';
import i18n from "i18next";
import enTranslation from "../i18n/en/translation.json";
import azTranslation from "../i18n/az/translation.json";
import rusTranslation from "../i18n/rus/translation.json";




const languageOptions = [
  { value: "en", label: "EN", flag: engflag },
  { value: "az", label: "AZ", flag: azflag },
  { value: "rus", label: "RU", flag: rusflag },
];

i18n.use(initReactI18next).init({
  resources: {
      en: { translation: enTranslation }, 
      az: { translation: azTranslation },
      rus: { translation: rusTranslation },
  },
  lng: localStorage.getItem("language") || "az",
  fallbackLng: "az",
  interpolation: { escapeValue: false },
});


const Header = () => {
  const { t, i18n } = useTranslation();
  const [isProductsOpen, setIsProductsOpen] = useState(false);
  const [isServicesOpen, setIsServicesOpen] = useState(false);
  const [isHamburgerMenuOpen, setIsHamburgerMenuOpen] = useState(false);
  const productsTimeoutRef = useRef(null);
  const servicesTimeoutRef = useRef(null);

  


  const openProductsMenu = () => {
    if (productsTimeoutRef.current) {
      clearTimeout(productsTimeoutRef.current);
    }
    setIsProductsOpen(true);
  };

  const closeProductsMenu = () => {
    productsTimeoutRef.current = setTimeout(() => { 
      setIsProductsOpen(false); 
    }, 200);
  };

  const openServicesMenu = () => {
    if (servicesTimeoutRef.current) {
      clearTimeout(servicesTimeoutRef.current);
    }
    setIsServicesOpen(true);
  };

  const closeServicesMenu = () => {
    servicesTimeoutRef.current = setTimeout(() => {
      setIsServicesOpen(false);
    }, 200);
  };
  const toggleProductsSubMenu = () => {
    setIsProductsOpen(!isProductsOpen);
  };
 
  const toggleServicesSubMenu = () => {
    setIsServicesOpen(!isServicesOpen);
  };

  const toggleHamburgerMenu = () => setIsHamburgerMenuOpen(!isHamburgerMenuOpen);


  const [isOpen, setIsOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(languageOptions.find(lang => lang.value === i18n.language));

  useEffect(() => {
      const savedLanguage = localStorage.getItem("language");
      if (savedLanguage) {
          const language = languageOptions.find(lang => lang.value === savedLanguage);
          if (language) {
              setSelectedLanguage(language);
              i18n.changeLanguage(language.value);
          }
      }
  }, []);

  const toggleDropdown = () => {
      setIsOpen(!isOpen);
  };

  const handleLanguageChange = (language) => {
      setSelectedLanguage(language);
      setIsOpen(false);
      i18n.changeLanguage(language.value);
      localStorage.setItem("language", language.value);
  };


  

  return (
    <>
      <nav className="bg-white border-b border-gray-200 py-2">
        <div className="max-w-container mx-auto">
          <div className="mx-auto flex items-center justify-between py-3 "> 
            <div>
              <a href='/'> <img src={logoOther} alt="Logo" className="h-16" /></a>
            </div>  
            <div className="hidden space-x-2 text-[10px] xl:text-[15px] 2xl:text-base 3xl:text-[17px] 2xl:space-x-6  lg:flex"> 
            <a href="/services" className="text-black text-base font-normaltext-[#086170]">{t("Xidmətlər")}</a>
            <a href="/about" className="text-black text-base font-normal">{t("Haqqımızda")}</a>
            <a href="/projects" className="text-black text-base font-normal">{t("Layihələrimiz")}</a>
              <a href="/contact" className="text-black text-base font-normal">{t("Əlaqə")}</a>
            </div>
            <div>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M11.5 2.75C6.66751 2.75 2.75 6.66751 2.75 11.5C2.75 16.3325 6.66751 20.25 11.5 20.25C16.3325 20.25 20.25 16.3325 20.25 11.5C20.25 6.66751 16.3325 2.75 11.5 2.75ZM1.25 11.5C1.25 5.83908 5.83908 1.25 11.5 1.25C17.1609 1.25 21.75 5.83908 21.75 11.5C21.75 14.0605 20.8111 16.4017 19.2589 18.1982L22.5303 21.4697C22.8232 21.7626 22.8232 22.2374 22.5303 22.5303C22.2374 22.8232 21.7626 22.8232 21.4697 22.5303L18.1982 19.2589C16.4017 20.8111 14.0605 21.75 11.5 21.75C5.83908 21.75 1.25 17.1609 1.25 11.5Z" fill="black"/>
</svg>
            </div>

            <div className="lg:hidden flex items-center relative ml-auto mr-10"> 
      <button className="text-[#086170] focus:outline-none" onClick={toggleHamburgerMenu}>
        <i className="fa-solid fa-bars text-[#086170]"></i>
      </button>
      {isHamburgerMenuOpen && (
        <div className={`fixed inset-y-0 left-0 transform ${isHamburgerMenuOpen ? 'translate-x-0' : '-translate-x-full'} transition-transform duration-300 ease-in-out bg-white w-[390px] z-50 shadow-lg`}>
          <button className="absolute top-5 right-5 text-white bg-[#086170] p-2 rounded" onClick={toggleHamburgerMenu}>
            <FaTimes />
          </button>
          <div className="flex flex-col items-start p-8">
            <a href="/" className="block px-4 py-2 text-[#086170] hover:bg-gray-100">{t("Əsas səhifə")}</a>
            <a href="/about" className="block px-4 py-2 text-[#086170] hover:bg-gray-100">{t("Haqqımızda")}</a>
            <div className="relative flex flex-col w-full">
              <div className="flex items-center w-full">
                <a href="/services" className="block px-4 py-2 text-[#086170] hover:bg-gray-100">{t("Xidmətlərimiz")}</a>
                <button
                  onClick={toggleServicesSubMenu}
                  className="ml-auto focus:outline-none"
                >
                  <FaChevronDown />
                </button>
              </div>
              {isServicesOpen && (
                <div className="ml-4">
                  <a href="/services" className="block px-4 py-2 text-[#086170] hover:bg-gray-100">{t("Bütün xidmətlər")}</a>
                  <a href="/muhasibatliq" className="block px-4 py-2 text-[#086170] hover:bg-gray-100">{t("Mühasibatlıq")}</a>
                  <a href="/maliyye" className="block px-4 py-2 text-[#086170] hover:bg-gray-100">{t("Maliyyə")}</a>
                  <a href="/vergi-ucotu" className="block px-4 py-2 text-[#086170] hover:bg-gray-100">{t("Vergi uçotu")}</a>
                  <a href="/insan-resurslari" className="block px-4 py-2 text-[#086170] hover:bg-gray-100">{t("İnsan resursları")}</a>
                  <a href="/proqramlasdirma" className="block px-4 py-2 text-[#086170] hover:bg-gray-100">{t("Proqramlaşdırma")}</a>
                  <a href="/e-kassa" className="block px-4 py-2 text-[#086170] hover:bg-gray-100">{t("E-kassa")}</a>
                  <a href="/sebeke-idareetmesi" className="block px-4 py-2 text-[#086170] hover:bg-gray-100">{t("Şəbəkə idareetmesi")}</a>
                </div>
              )}
            </div>
            <a href="/partners" className="block px-4 py-2 text-[#086170] hover:bg-gray-100">{t("Müştərilərimiz")}</a>
            <div className="relative flex flex-col w-full">
              <div className="flex items-center w-full">
                <a href="/products" className="block px-4 py-2 text-[#086170] hover:bg-gray-100">{t("Məhsullar")}</a>
                <button
                  onClick={toggleProductsSubMenu}
                  className="ml-auto focus:outline-none"
                >
                  <FaChevronDown />
                </button>
              </div>
              {isProductsOpen && (
                <div className="ml-4">
                  <a href="/products" className="block px-4 py-2 text-[#086170] hover:bg-gray-100">{t("Bütün məhsullar")}</a>
                  <a href="/pul-qutulari" className="block px-4 py-2 text-[#086170] hover:bg-gray-100">{t("Pul qutuları")}</a>
                  <a href="/barkod-processor" className="block px-4 py-2 text-[#086170] hover:bg-gray-100">{t("Barkod Prosessor")}</a>
                  <a href="/barkod-oxuyucu" className="block px-4 py-2 text-[#086170] hover:bg-gray-100">{t("Barkod oxuyucu")}</a>
                  <a href="/card-reader" className="block px-4 py-2 text-[#086170] hover:bg-gray-100">{t("Card reader")}</a>
                  <a href="/data-terminal" className="block px-4 py-2 text-[#086170] hover:bg-gray-100">{t("Data terminal")}</a>
                  <a href="/pos-printer" className="block px-4 py-2 text-[#086170] hover:bg-gray-100">{t("Pos printer")}</a>
                  <a href="/barkod-terezi" className="block px-4 py-2 text-[#086170] hover:bg-gray-100">{t("Barkod Tərəzi")}</a>
                </div>
              )}
            </div>
            <a href="/program-software" className="block px-4 py-2 text-[#086170] hover:bg-gray-100">{t("Proqram təminatı")}</a>
            <a href="/documents" className="block px-4 py-2 text-[#086170] hover:bg-gray-100">{t("Ərizə formaları")}</a>
            <a href="/contact" className="block px-4 py-2 text-[#086170] hover:bg-gray-100">{t("Əlaqə")}</a>

            <div className="relative inline-flex items-center space-x-4 mt-4 sm:mt-0 sm:ml-0 flex-wrap sm:flex-nowrap">
            <span className="font-nunito text-lg">{t("2021-ci ildən Sizinlə")}</span>
          </div>
          </div>
        </div>
      )}
    </div> 
            <div className="hidden sml:block"> 
              {/* <a href="/contact">
                <button className="bg-[#086170] text-xs text-white px-1 py-2 rounded font-bold scaling-button">
                  {t("Ödənişsiz yükləyin")}
                </button>
              </a> */}

              <style jsx>{`
                @keyframes scale {
                  0%, 100% {
                    transform: scale(1);
                  }
                  50% {
                    transform: scale(1.1);
                  }
                }
                .scaling-button {
                  animation: scale 1.5s infinite ease-in-out;
                }
              `}</style>
            </div>
          </div>
        </div>
      </nav>            
    </>
  ); 
};

export default Header;



