import { useEffect } from "react";
import {
  createBrowserRouter,
  RouterProvider,
  Outlet,
  createRoutesFromElements,
  Route,
  ScrollRestoration,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import AboveHeader from "./Components/AboveHeader/AboveHeader";
import Header from "./Components/Header/Header";
import Footer from "./Components/Footer/Footer";
import Home from "../src/Pages/Home/Home";
import About from "./Pages/About/About";
import Services from "./Pages/Services/Services";
import Contact from "./Pages/Contact/Contact";
import Partners from "./Pages/Partners/Partners";

import PulQutulari from "./Pages/AllProducts/PulQutulari/PulQutulari";
import AllInOne from "./Pages/AllProducts/AllInOne/AllInOne";
import BarkodProcessor from "./Pages/AllProducts/BarkodProsessor/BarkodProsessor";
import CardReader from "./Pages/AllProducts/CardReader/CardReader";
import DataTerminal from "./Pages/AllProducts/DataTerminal/DataTerminal";
import PosPrinter from "./Pages/AllProducts/PosPrinter/PosPrinter";
import BarkodOxuyucu from "./Pages/AllProducts/BarkodOxuyucu/BarkodOxuyucu";
import BarkodTerezi from "./Pages/AllProducts/BarkodTerezi/BarkodTerezi";
import ProductDetail from "./Pages/ProductDetail/ProductDetail";

import DetailedPage from "./Pages/DetailedPage/DetailedPage.js";
import { FloatingWhatsApp } from "react-floating-whatsapp";
import Documents from "./Pages/Documents/Documents.js";
import ProgramSoftware from "./Pages/ProgramSoftware/ProgramSoftware.js";
import Blogs from "./Pages/Blogs/Blogs.js";
import BlogDetail from "./Pages/BlogDetail/BlogDetail.js";
import Kataloq from "./Pages/Kataloq/Kataloq.js";
import Projects from "./Pages/Projects/Projects.js";
import All from "./Pages/AllServices/All/All.js";
import Infrastruktur from "./Pages/AllServices/Infrastruktur/Infrastruktur.js";
import Tikinti from "./Pages/AllServices/Tikinti/Tikinti.js";
import Interyer from "./Pages/AllServices/Interyer/Interyer.js";
import Eksteryer from "./Pages/AllServices/Eksteryer/Eksteryer.js";
import Texnika from "./Pages/AllServices/Texnika/Texnika.js";

const Layout = () => {
  return ( 
    <div>
       <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover 
        theme="colored"
      /> 
      <ScrollRestoration />
      <Outlet />
      <Footer />
    </div>
  );
};

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route path="/" element={<Layout />}>
        <Route path="/" element={<Home />}></Route>
        <Route path="/about" element={<About />}></Route>
        <Route path="/kataloq" element={<Kataloq />}></Route>
        <Route path="/services" element={<Services />}></Route>
        <Route path="/services/all" element={<All />}></Route>
        <Route path="/services/infrastruktur" element={<Infrastruktur />}></Route>
        <Route path="/services/tikinti" element={<Tikinti />}></Route>
        <Route path="/services/interyer" element={<Interyer />}></Route>
        <Route path="/services/eksteryer" element={<Eksteryer />}></Route>
        <Route path="/services/texnika" element={<Texnika />}></Route>
        <Route path="/projects" element={<Projects />}></Route>
        <Route path="/blogs" element={<Blogs />}></Route>
        <Route path="/partners" element={<Partners />}></Route>
        <Route path="/documents" element={<Documents />}></Route>
        <Route path="/contact" element={<Contact />}></Route>
        <Route path="/detailed-page" element={<DetailedPage />} />
        
        <Route path="/pul-qutulari" element={<PulQutulari />} />
        <Route path="/all-in-one" element={<AllInOne />} />
        <Route path="/barkod-processor" element={<BarkodProcessor />} />
        <Route path="/card-reader" element={<CardReader />} />
        <Route path="/data-terminal" element={<DataTerminal />} />
        <Route path="/pos-printer" element={<PosPrinter />} />
        <Route path="/barkod-oxuyucu" element={<BarkodOxuyucu />} /> 
        <Route path="/barkod-terezi" element={<BarkodTerezi />} />
        <Route path="/product-detail/:id" element={<ProductDetail />} />
        <Route path="/blog-detail/:id" element={<BlogDetail />} />

        <Route path="/program-software" element={<ProgramSoftware />} />
      </Route>
    </Route>
  )
);

function App() {

  return (
    <div className="">
      <div className="font-bodyFont">
      <div>
    {/* <FloatingWhatsApp phoneNumber='+994552062366' accountName="INTEKO" statusMessage='' chatMessage='Salam, sizə necə kömək edə bilərik?' placeholder='Mesaj yazın...' avatar='./avatar.png' messageDelay='1' /> */}
      <RouterProvider router={router} />
      </div>
    </div>
    </div>
  );
}

export default App;